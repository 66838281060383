import Logo1 from '../../assets/basket.jpg';
import Logo2 from '../../assets/klasik.jpg';
import Logo3 from '../../assets/atletika.jpg';

// Add as many logos as you collaborate with



const Teams = () => {
    return (
        <div className="bg-[#6B7A8F] p-6 text-center">
            <h2 className="text-2xl md:text-4xl font-semibold mb-8 text-gray-100 ">Spolupráce</h2>
            <div className="flex flex-wrap justify-center items-center gap-2 md:gap-32">
                <img src={Logo1} alt="Collaborator Logo 1" className="h-36 md:h-48"/>
                <img src={Logo2} alt="Collaborator Logo 2" className="h-36 md:h-48"/>
                <img src={Logo3} alt="Collaborator Logo 3" className="h-36 md:h-48"/>
                {/* Repeat for as many logos as you have */}
            </div>
        </div>
    );
};
export default Teams;