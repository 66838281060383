import React from 'react';
import Image2 from '../../assets/images/2.jpeg';
import Image5 from '../../assets/images/5.jpeg';
import Image6 from '../../assets/images/6.jpeg';
import Image11 from '../../assets/images/11.jpeg';
import Image1 from '../../assets/images/1.jpeg';
import './style.css';
import MyMapComponent from "../../components/map/map";


const AboutSection = () => {
    return (
        <div className="md:px-8 md:py-8 w-full bg-[#F5F5F5]">
            <div className="flex flex-col md:flex-row justify-between items-center w-full my-8">
                <div className="w-full md:w-1/2 p-4 corner-border mx-2">
                    <h3 className="mb-4 text-4xl leading-tight ">O UrbanFyzio</h3>
                    <p className="text-lg leading-relaxed">
                        Soukromá ambulance UrbanFyzio poskytuje profesionální služby v oblasti fyzioterapie a
                        rehabilitace.
                        Zakládáme si na zcela individuálním přístupu a využíváme nejmodernější postupy s maximální
                        odborností.

                    </p>
                    <h3 className="mb-4 mt-6 text-4xl leading-tight">Co nabízíme?</h3>
                    <p className="text-lg leading-relaxed">
                        Nabízíme širokou škálu služeb od rehabilitace po úrazech a operacích až po specializované terapeutické programy pro vrcholové sportovce.
                        Jsme tu pro vás, ať už se potřebujete zotavit po náročném tréninku, hledáte úlevu od chronické bolesti
                        nebo se snažíte zlepšit svůj celkový zdravotní stav.
                    </p>
                </div>
                <div className="w-full md:w-1/2 p-4 transition-transform transform">
                    <img src={Image2} alt="About Image"
                         className="w-3/4 h-3/4 object-cover rounded-md shadow-lg hover:shadow-xl"/>
                </div>
            </div>


            <div className="w-full md:w-fit p-4">
                <div className="grid grid-cols-2 gap-4">
                    <img src={Image5} alt="Gallery image 1" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image6} alt="Gallery image 2" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image11} alt="Gallery image 3" className="w-full object-cover rounded-md shadow-lg"/>
                    <img src={Image1} alt="Gallery image 4" className="w-full object-cover rounded-md shadow-lg"/>
                </div>
            </div>


            <div className="text-center mt-12 mb-8">
                <h3 className="mb-4 text-4xl font-semibold leading-tight">Máme volné termíny!</h3>
                <p className="text-lg leading-relaxed max-w-3xl mx-auto">
                    Přijďte se přesvědčit o naší kvalitě!
                    <br/>
                    <a href="https://urbanfyzio.reservio.com/"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="underline font-semibold text-[#0199ff]">Rezervujte se zde</a>
                    <br/>
                    nebo volejte
                    <br/>
                    <a href="tel:+420734312843" className="hover:text-gray-400 font-bold">+420 734 312 843</a>
                    <br/>
                    Najdete nás na adrese:
                    <br/>
                    <p className="font-bold">Palackého 131, Frýdek-Místek</p>
                </p>
            </div>
            <MyMapComponent center={[49.67464065551758, 18.343393325805664]} zoom={25} />


        </div>
    )
        ;
};

export default AboutSection;
